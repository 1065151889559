<template>

  <div class="createTenantAlias">
      <b-container>
            
    <b-card bg-variant="light" class="text-center">
        <h2>Create Tenant Alias</h2>
        <p>Requests to update chef and Akamai are fired through Via to RunDeck. <br/> A succesful response however does not mean these requests completed successfully.</p>
        <b-form id="requestForm" @submit.prevent="sendRequest">
                <UserAuthentication v-model="auth" usernameLabel="Username" passwordLabel="Password"/>
                <hr class="solid">
                    <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" id="tenantName" label="Tenant Name" label-for="tenantName">
                <b-form-input id="tenantName" type="text" v-model="request.tenantName" required name="Tenant Name" v-validate="{ regex: /^[a-z0-9]*-?[a-z0-9]*$/ }" placeholder="Enter Tenant name"></b-form-input>
                </b-form-group>
                <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" id="tenantAliasName" label="Tenant Alias Name" label-for="tenantAliasName">
                    <b-form-input id="tenantAliasName" type="text" v-model="request.tenantAliasName" required name="Tenant Alias Name" v-validate="{ regex: /^[a-z0-9]*-?[a-z0-9]*$/ }" placeholder="Enter Tenant Alias name"></b-form-input>
                </b-form-group>
                <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" id="region" label="Region" label-for="region">
                    <b-form-select id="region" required v-model="request.region">
                        <option value="" disabled selected>Please select Region</option>
                        <option v-for="region in regions" :key="region">{{region}}</option>
                    </b-form-select>
                </b-form-group>
                <div class="alert alert-danger" v-show="veeErrors.any()">
                    <div v-if="veeErrors.has('Tenant Name')">{{ veeErrors.first('Tenant Name') }}</div>
                </div>
                <b-alert :show="responses != ''"  variant="success">{{responses}}</b-alert>
                <b-alert :show="errors != ''"  variant="danger">{{errors}}</b-alert>
                <b-button type="submit" variant="primary">Submit</b-button>
            </b-form>
        </b-card>
      </b-container>
    </div>
</template>


<script>
import Forge from "node-forge";
import axios from 'axios'
import UserAuthentication from '@/components/UserAuthentication.vue';
import helpers from '@/helpers.js';

export default {
  name: 'CreateTenantAlias',
  components: {
      UserAuthentication
    },
  data ()  {
    return {
        responses: '',
        errors: '',
        auth: {
            username: '',
            password: ''
        },
        request: {
            tenantName: '',
            tenantAliasName: '',
            region: ''
        },
        regions: []
    }
  },
  created() {
    this.getRegions()
  },
  methods:{
      sendRequest: function(event){
        var request = {
            'auth': {
                'username': this.auth.username,
                'password': helpers.md5HashPwd(Forge, this.auth.password),
            },   
            'tenantName': this.request.tenantName,
            'tenantAliasName': this.request.tenantAliasName,
            'region': this.request.region
        };

        axios.post('/api/tenant/createAlias', request)
            .then(response => {
                if (response.data.error != "" && response.data.error != null) {
                    this.errors = response.data.error;
                    this.responses = '';
                }
                if (response.data.success != "" && response.data.success != null) {
                    this.responses = response.data.success;
                    this.errors = '';
                    this.auth.username = "";
                    this.auth.password = "";
                    this.request.tenantName = "";
                    this.request.tenantAlias = "";
                    this.request.region = "";
                }
            }).catch(e => {
                this.errors = e;
            });
    },
    getRegions: function() {
        axios.get("/api/regions").then(response => {
            this.regions = response.data.regions;
        }).catch(e => {
            this.errors = e;
        });
    }
    }
}
</script>
